/* Hide the scrollbar */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.item-lists li a {
    /* Keep font weight consistent */
    display: inline-block;
    /* Ensure inline-block behavior to maintain layout */
    /* padding: 12px 20px; */
    /* Padding for consistent spacing */
    transition: all 0.3s ease-in-out;
    /* Smooth transition for hover effects */
}

.item-lists li a:hover {
    text-decoration: none;
    /* Remove any default underline */
    background: linear-gradient(to top, #0D6EB5, #2AABE2);
    ;
    /* Background color change on hover */
    color: #FFFFFF;
    /* Text color change on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Add subtle shadow */
    transform: scale(1.05);
    /* Slight scaling on hover */
}

.activeTab {
    background: linear-gradient(to top, #0D6EB5, #2AABE2);
    ;
    /* Background color change on hover */
    color: #FFFFFF;
    /* Text color change on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Add subtle shadow */
    transform: scale(1.05);
}