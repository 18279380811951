.header-container {
    background-image: url('https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/BlogPageAssest/header-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    /* height: auto; */
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Show only 4 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* media queries for adjusting header bg image */

/* for mobile display */

@media screen and (max-width: 640px) {

    /* adjust header bg image */
    .header-container {
        height: 340px;
    }

}