.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Show only 4 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


