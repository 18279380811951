@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  box-sizing: border-box;
}

/* Butto Styling */
.About_btn {
  height: 45px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

/* .About-btn:hover{
 
} */

.home_button:hover {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* border: none; */
}



.home_button {
  border: 1px solid #137BBF;
  color: #137BBF;
  font-weight: 500;

  width: Fixed (191px)px;
  height: Fixed (45px)px;

  padding: 14px 24px 14px 24px;
  gap: 0px;

}

.home_button:hover {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  color: white;
  /* border: none; */
}

.help_center {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: #ffffff;
  padding: 5px 20px 5px 20px;
  gap: 0px;
  font-size: 16px;
}

.help_center:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* font-weight: bolder; */
  /* Slight zoom effect on hover */
}


.Speak_btn {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: #ffffff;
  padding: 12px 20px 12px 20px;
  gap: 0px;

  font-size: 16px;
}

.Speak_btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* font-weight: bolder; */
  /* Slight zoom effect on hover */
}

/* Explore all btn */
.explore_all_btn {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: #ffffff;
  padding: 12px 10px 12px 10px;
  gap: 0px;
  width: 70%;
  font-size: 16px;
  border-radius: 27px;
}

.explore_all_btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* font-weight: bolder; */
  /* Slight zoom effect on hover */
}



.view_all {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: white;
  height: Fixed (30px)px;

  padding: 14px 24px 14px 24px;
  gap: 0px;

}

.view_all:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /*  font-weight: bolder; */
  /* Slight zoom effect on hover */
}

.Read-More-btn {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: white;
  height: Fixed (30px)px;

  padding: 14px 24px 14px 24px;
  gap: 0px;

}

.Read-More-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* font-weight: bolder; */
  /* Slight zoom effect on hover */
}

.demo_btm {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  font-weight: 500;
  color: white;
  height: 40px;
  /* Fixed height */
  padding: 10px 20px;
  /* Consistent padding */
  display: inline-block;
  /* Prevent element shift */
  line-height: 1.5;
  /* Maintain height consistency */
  gap: 0px;
  opacity: 1;
  /* Ensure button is visible */
  transition: all 0.3s ease;
  /* Smooth transition */
}

.demo_btm:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow on hover */
  transform: scale(1.05);
  /* Slight zoom effect on hover */
  font-weight: 600;
  /* Bolder text on hover */
  opacity: 1;
  /* Keep opacity at 1 */
}

.border1 {
  border: 3px solid black;
}

.nav-btn:hover {
  background: linear-gradient(to top, #0D6EB5, #2AABE2);
  color: white;
}

.nav-btn {
  color: #0D6EB5;
  border: 1px solid #2AABE2;
}

nav {
  background-color: white;
}


/* Button Class End */

.borders {
  border: 4px solid red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Home Page Css */

/* Gradient Image Top-Left */
.gradient-image-top-left {
  position: absolute;
  bottom: 0;
  left: -50%;
  width: 100%;
  height: auto;
  z-index: 0;
  /* Lower z-index to keep it behind the text */
}

/* Gradient Image Bottom-Right */
.gradient-image-bottom-right {
  position: absolute;
  top: 0;
  right: -50%;
  width: 100%;
  height: auto;
  z-index: 0;
  /* Lower z-index to keep it behind the text */
}

.text-heading-container {
  letter-spacing: -0.5px;
}



@media (max-width: 640px) {

  .heading {
    font-size: 25px !important;
    width: 100%;
  }

  .paragraph {
    font-size: 16px !important;
  }

  .btn-box {
    margin-top: 2rem !important;
  }



  .home-image {
    height: 20rem !important;
  }

  .About-button {
    height: 15%;
    width: 35%;
  }
}

/* Home Page About Section */
.About-btn {
  border: 1px solid #137BBF;
  color: #0D6EB5;
  background-color: #DCF4FF;
}

.about-text-box {
  text-align: center;
}

/*  .About-button {
     height: 37px;
     width: 128px;
 } */

.About-Heading {
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
  /*     text-align: left;
 */
  /* Reduce the gap between lines */
}



@media (min-width: 1024px) {
  .about-text-box {
    text-align: left !important;
  }
}

.About-box {
  gap: 75px;
}




/* Home Service Css  */


/* Media Query */
@media (min-width: 768px) {
  .md\:m-6 {
    margin: 0rem !important;
  }
}

/* Home Industry Css */

/* .bg-img{
    background-image: url('../../assets/Industry/industry-background.png');
    border: 4px solid black;
    object-fit: cover;
} */
.flex-center-children>div {
  @apply flex justify-center items-center;
}

.About-text {
  z-index: 2;
}

.industry-texts {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}



@media (max-width: 640px) {
  .flex-center-children>div {
    @apply flex justify-center items-center;
    margin-bottom: 2rem;
  }

  .About-text {
    text-align: center !important;
    margin-bottom: 2rem;
    z-index: 2;
  }

  .industry-bg-img {
    display: none;
  }

  .industry-heading {
    font-size: 43px;
  }

  .industry-text {
    font-size: 15px;
  }

  .industry-img-1 {
    width: 100%
  }

  .industry-img-2 {
    width: 85%
  }

  .custom-sm-span-2 {
    grid-column: span 3 / span 2;
    margin-bottom: 3rem;
  }

  .arrow_small {
    position: relative !important;
    left: 1rem;
    top: -1rem;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {

  /* .industry-bg-img {
      width: 92%;
      height: 26rem;
    } */

  .industry_text_Section {
    left: 0rem;
    top: 3rem;
  }


  .industry-bg-img {
    display: none;
  }

  .custom-sm-span-2 {
    grid-column: span 3 / span 2;
  }


}

/* For small screen then 640 hide the bg images of sector*/

@media (max-width: 640px) {
  .bg_img {
    border: 2px black !important;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .about-text-para {
    text-align: center !important;
  }
}

/* Custom CSS */
.vertical-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  /* Line thickness */
  background-color: #ccc;
  /* Line color */
  z-index: 1;
  /* Make sure it's above the image */
}

.relative {
  position: relative;
  /* Necessary for absolute positioning inside */
}

/* Hide the line on the last item */
.grid>div:last-child .vertical-line {
  display: none;
}

/* Home Section Css End */

/* About Page Css Start */

.about-text-heading {
  font-family: Poppins;
  font-size: 46.67px;
  font-weight: 600;
  line-height: 56px;
  color: #272626;
  text-align: center;
  /* Default for small screens */
}

.about-text-para {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}



/* Media Query */
@media (min-width: 1024px) {
  .about-text-heading {
    text-align: center;
    width: 100%;
    /* On large screens */
  }

  .about_info {
    text-align: left !important;
  }
}





.about_info {
  text-align: center;
}

/* About Page Css End */

/* Sector Component Css Start */

/* Govt API Component CSS */

.govtAPICard {
  width: 100%;
  height: 230px;

  gap: 0px;
  border-radius: 25px;
  border: 2px solid #E9F7FD;

  box-shadow: 0px 12px 23px 0px #2582F012;
  background: #fff;
}

.govtAPICard_child {
  gap: 0px;

}

.govt_card_shadow {
  width: 56.35px;
  height: 4.8px;
  gap: 0px;
  opacity: 0.2;
  background: linear-gradient(137.6deg, #2AABE2 -6.53%, #0D6EB5 105.38%);
}

.govt_card_heading {
  font-family: poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: black;
}

/* IT Sector Page CSS */

.industry_heading {
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
  color: #272626;
  text-align: center;
  text-transform: capitalize;
}

.industry_info {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0D6EB5;

}

.sector_main_box {
  width: auto;
  height: 442.03px;

  gap: 0px;

  /*  border: 3px solid red; */
}

.sector_child_box {
  width: 100%;
  height: 66.18px;
  border-radius: 11px 0px 0px 0px;

  /*  border: 3px solid yellowgreen; */
}

.sector_child_box_heading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.sector_child_box_para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}


/* Media Query */
@media (min-width: 1024px) {
  .industry_heading {
    text-align: left;
    /* On large screens */
  }

  .about_info {
    text-align: left !important;
  }

  .sector_child_box_para {
    text-align: left;
  }

  .sector_main_box {
    width: 30rem;
    height: 442px;

    gap: 0px;

    /*  border: 3px solid red; */
  }


}

@media (max-width: 768px) {
  .sector_child_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .background_color {
    background: #E4F2FF;
  }


}

@media (max-width: 640px) {
  .sector_child_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }



  .background_color {
    background: #E4F2FF;
  }

  .sector_image {
    display: none;
  }

  .sector_child_box_para {
    text-align: center;
  }

  .sector_child_box_paragraph {
    text-align: left;
  }



  .sector_child_box_heading {
    font-size: 18px;
  }

  /* 
        .bg_curve_img {
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100;
            height: 100%;
            z-index: -1;
        }
    */

  .IT_Section {
    margin-top: 5rem;
  }

  .govt_api_btn {
    width: 50%;
  }
}

/* @media (max-width: 768px) {
.IT_Section {
  height: 100%;
  border: 2px solid black;
}
} */
.IT_Section {
  width: auto;
  height: 90%;
  /*  padding: 108.5px 44px 27.5px 34.95px; */
  gap: 0px;
  border-radius: 25px;
  border: 2.7px 0px 0px 0px;

  border: 2.7px solid #D1EDFF;

}

.IT_Service_box {
  width: 135.17px;
  height: 135.17px;
  border: 7.14px 0px 0px 0px;

  background: #E6F5FF;

}

.IT_Service_Heading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin-top: 5rem;

}

.IT_service_Para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}




/* IT Sector Setion 5 */

.Sector_Section_5 {
  width: 100%;
  height: 209px;
  background: #fff;
  gap: 0px;
  border-radius: 25px !important;

  box-shadow: 2.9px 1.56px 3.67px 0px #22769921;
}

.Sector_Section_5_child {
  width: 173.78px;
  gap: 0px;

}

.Sector_Section_5_text {
  font-family: poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  /*  text-align: left; */
}

/* Banking Sector Css */




@media (min-width: 1024px) {
  .Banking_heading {
    text-align: left;
    /* On large screens */
  }

}

/* Sector Css End */





/* -------------------------footer css------------------------------------ */

/* @media (max-width: 640px) {
  .footer {
    display: none;
  }

  .footer-heading {
    font-family: poppins;
    width: 62px;
    height: 24px;
    gap: 0px;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text {

    gap: 0px;
    opacity: 0.8;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text2 {

    gap: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text3 {

    gap: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: center;
    color: #ffffff;
  }

  .bg {
    background-color: #1885C6 !important;
  }

  .footer-img {
    display: none;
  }
}

.footer-heading {
  font-family: poppins;
  height: 24px;
  gap: 0px;
  
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.footer-text {

  gap: 0px;
  opacity: 0.8;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: left;
  color: #ffffff;
}

.footer-text2 {

  gap: 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: left;
  color: #ffffff;
}

.footer-text3 {
  gap: 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .footer {
    display: none;
  }

  .footer-heading {
    font-family: poppins;
    width: 62px;
    height: 24px;
    gap: 0px;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text {

    gap: 0px;
    opacity: 0.8;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text2 {

    gap: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: left;
    color: #ffffff;
  }

  .footer-text3 {

    gap: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    text-align: center;
    color: #ffffff;
  }

  .bg {
    background-color: #1885C6 !important;
  }

  .footer-img {
    display: none;
  }
} */


/* Blog Background Image */
/* .blog_bg_image {
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
} */
.blog_bg_image {
  background: url("https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Portal_Image/Portal_bg.png");
  background-size: cover;
  /* Ensures the image covers the div */
  background-position: center;
  /* Keeps the image centered */
  background-repeat: no-repeat;
  width: 100%;
}



@media (max-width: 640px) {
  .bottom_img {
    top: 3rem !important;
  }

  .bottom_img_gigworker {
    top: 2.6rem;
  }

  .blog_img {
    display: none;
  }

  .blog_img_small {
    top: 30rem;
  }


  /* Service Small box content*/
  .service-box {
    display: flex;
    flex-direction: column !important;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  .boxes {
    width: 70px !important;
    height: 63px !important;
    gap: 0px;
    border-radius: 7px !important;
  }

  .small_screen {
    margin-left: 0.6rem;
  }

  .service_centent_box {
    margin-top: 0rem !important;
    margin-bottom: 1rem;
    /* margin-left: 0.6rem; */
    /* margin-right: 3rem; */
  }
}

.blog_heading {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 600;
  line-height: 57.6px;
  text-align: center;

}

.blog_para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}


/* ------------------- */

.service-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* Ensures items start at the top */
  justify-content: flex-start;
  min-height: 200px;
  border-radius: 29px;
  /* Set a minimum height for the service boxes */
  /*  border: 2px solid black; */
  transition: background-color 0.3s ease;
  box-shadow: 0px 16.91px 26.76px 0px #00000014;
  border: 0.99px solid #00000033;
  background-color: #fff;
  padding-right: 1.5rem;
}

.boxes {
  width: 60px;
  height: 63px;
  flex-shrink: 0;
  padding: 10px;
  /* Adjust as needed */
  top: 25px;
  left: 0px;
  gap: 0px;
  border-radius: 0px 59.69px 59.69px 0px;

  /*  border: 4px solid red; */
  transition: background-color 0.3s ease;
  background-color: #1988c8;

}

.small_screen {
  margin-top: 0.2rem;
}

.service_centent_box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.service-text {
  flex-grow: 1;
  /* This ensures that the text grows to fill available space */
}

.service-btn {
  margin-top: 10px;
  /* Adds space between the text and button */
}



.service-btn {
  font-family: Poppins;
  font-size: 15.92px;
  font-weight: 500;
  text-align: left;
  margin-top: 1rem;
  color: #0D6EB6;
  /* Default button color */
  transition: color 0.3s ease;
}



.rotate-icon {
  display: inline-block;
  margin-left: 3px;
  /* Required for the transform to work properly */
  transition: transform 0.3s ease;
  /* Optional: for smooth rotation */
  transform: rotate(45deg);
  /* Rotates the icon 45 degrees to the right */
}

/* Change the color of the button to white when hovering over .service-box */
.service-box:hover .service-btn {
  color: #fff;
}

.service-box:hover {
  background-color: #1b8bca;
  /* Background turns blue on hover */
  color: #fff;
}

.service-box:hover .boxes {
  background-color: #fff;
  /* Box turns white on hover */
}

.service-box svg path {
  stroke: white;
  /* Default stroke color */
  transition: stroke 0.3s ease;
}

/* Change SVG color when hovering over .service-box */
.service-box:hover svg path {
  stroke: #1b8bca;
  /* Change SVG stroke to #1b8bca on hover */
}

.service-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;

}

.service-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  text-align: left;
}


/* Media Query */

@media (max-width: 640px) {
  .service_small_box {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (max-width: 768px) {
  .top_spacing {
    margin-top: 6rem;
  }
}

.border1 {
  border: 6px solid black;
}


/* Portal Css  */

.Features_heading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
}

.Features_text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.side_border {
  position: relative;
  padding: 16px;
  /* Adjust padding to your liking */
}

.side_border::before,
/* Left border */
.side_border::after,
/* Right border */
.side_border .top_border,
/* Top border */
.side_border .bottom_border

/* Bottom border */
  {
  content: '';
  position: absolute;
  background-color: #9CE2FF;
}

/* Left border */
.side_border::before {
  top: 20%;
  /* Adjust based on how centered you want the border */
  left: 0;
  /* width: 1px; */
  /* Border width */
  height: 60%;
  /* Border height to cover 60% of the card's height */
}

/* Right border */
.side_border::after {
  top: 20%;
  right: 0;
  /*  width: 1px; */
  height: 60%;
}

/* Top border */
.side_border .top_border {
  top: 0;
  left: 20%;
  /* Center horizontally */
  width: 60%;
  height: 1px;
}

/* Bottom border */
.side_border .bottom_border {
  bottom: 0;
  left: 20%;
  /* Center horizontally */
  width: 60%;
  height: 1px;
}

.side_box {
  border: 1.26px solid #A3E4FF;
  border-radius: 5px;
}

/* Spacing in Screening card */
.card_padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Main Section Images */

/* CSS file for Offer_Portal */

.large-image {
  display: block;
  /* Show by default */
}

.small-image {
  display: none;
  /* Hide by default */
}

@media (max-width: 1024px) {
  .large-image {
    display: none;
    /* Hide large image on screens less than 1024px */
  }

  .self-image {
    display: none;
  }

  .small-image {
    display: block;
    /* Show small image on screens less than 1024px */
  }
}

@media (min-width: 1024px) {
  .large-image {
    display: block;
  }

  .self-image {
    display: block;
  }

  .small-image {
    display: none;
    /* Hide small image on screens 1024px and above */
  }
}


/* 1st Page Industry Section */
.IT_Headings {
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
}


@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .blog_bg_img {
    background: url("https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Portal_Image/Portal_bg.png");
    background-size: cover;
    /* Ensures the image covers the div */
    background-position: center;
    /* Keeps the image centered */
    background-repeat: no-repeat;
    width: 100%;
    height: 62rem !important;

  }

  .blog-box {
    position: relative;
    top: 4rem;
  }

  .small-screen {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {

  .small-screen {
    display: none;
  }

  .blog_bg_img {
    background: url("https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Portal_Image/Portal_bg.png");
    background-size: cover;
    /* Ensures the image covers the div */
    background-position: center;
    /* Keeps the image centered */
    background-repeat: no-repeat;
    width: 100%;
    height: 57rem;

  }

  .blog-box {
    position: relative;
    top: 4rem;
  }

  .small_size {
    display: none;
  }



}

@media screen and (min-width: 1280px) and (max-width: 2560px) {

  .small-screen {
    display: none;
  }

  .main-box {
    top: 9rem !important;
  }

  .GR-portal-box {
    top: 6rem;
  }

  .portal-box {
    top: 5rem;
  }

  .Self-portal-box {
    top: 7rem !important;
  }

  .industry-bg-img {
    width: 92%;
    height: 24rem;
  }

  /*   .help_card {
    top: 7rem;
  } */

  .home_image {
    top: -6rem;
  }

  .Banking_heading {
    font-family: Poppins;
    font-size: 42px !important;
    font-weight: 600;
    line-height: 56px;
    color: #272626;
    text-align: left;
    text-transform: capitalize;
  }

  .IT_Heading {
    font-family: Poppins;
    font-size: 42px;
    font-weight: 600;
    line-height: 56px;
    text-align: left;
    text-transform: capitalize;
  }

  .about-text-para {
    text-align: left;
    width: 80%;
    /* On large screens */
  }

  .arrow_img {
    margin-left: 0.5rem;
  }

  .top_Space {
    margin-top: 20rem;
  }

  /*   .hirestar_Icon {
      position: relative;
      top: -4rem;
      left: 1.5rem;
      border: 2px solid black;
    }
    .box_height{
      margin-top: px;
      border: 2px solid black;
    } */
  .section_spacing1 {
    margin-top: 20rem !important;
  }

  /* .section_spacing2 {
  margin-top: 5rem !important;
} */


  .small_size {
    display: none;
  }


}


@media screen and (min-width: 769px) and (max-width: 1024px) {

  .small-screen {
    display: none;
  }



  .industry_child_Section16 {
    margin-right: 2rem !important;
    margin-top: 1rem !important;
  }

  .industry_child_Section9 {
    margin-left: 2rem !important;
  }

  .industry_child_Section10 {
    margin-left: 4rem !important;
  }

  .main-box {
    top: 7rem !important;
  }

  .home-image {
    top: -11rem;
  }

  .self-image {
    position: relative;
    top: -4rem;
  }

  .top_image_gap {
    top: 11rem !important;
    margin-bottom: 9rem;
  }

  .top_image_gap1 {
    top: 7rem !important;
    margin-bottom: 9rem;
  }

  .large-image {
    position: relative;
    top: 10rem;
  }

  .top_image_gap3 {
    top: 8rem !important;
    margin-bottom: 9rem;
  }

  /*  .image_gap {
    top: 1rem;
  } */
  .section_spacing2 {
    /*  margin-top: 5rem !important; */
    position: relative;
    top: -6rem;
  }

  .large-image1 {
    top: 10rem !important;
    margin-bottom: 9rem;
  }
}



@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .blog_bg_img {
    background: url("https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Portal_Image/Portal_bg.png");
    background-size: cover;
    /* Ensures the image covers the div */
    background-position: center;
    /* Keeps the image centered */
    background-repeat: no-repeat;
    width: 100%;
  }

  .large-image {
    position: relative;
    top: 6rem;
  }

  .large-image1 {
    top: 10rem !important;
    margin-bottom: 9rem;
  }


  .top_image_gap1 {
    top: 7rem !important;
    margin-bottom: 9rem;
  }

  .top_Space {
    margin-top: 16rem;
  }

  .arrow_img {
    margin-left: 0.5rem;
  }

  .about-text-para {
    text-align: left;
    width: 80%;
    /* On large screens */
  }

  .Banking_heading {
    font-family: Poppins;
    font-size: 42px !important;
    font-weight: 600;
    line-height: 56px;
    color: #272626;
    text-align: left;
    text-transform: capitalize;
  }

  .IT_Heading {
    font-family: Poppins;
    font-size: 42px;
    font-weight: 600;
    line-height: 56px;
    text-align: left;
    text-transform: capitalize;
  }

  .industry_text_Section {
    left: 5rem;
  }

  .industry_child_Section1 {
    left: 0.5rem;
    top: 1rem !important;
  }

  .industry_child_Section2 {
    left: 1rem;
    top: 1rem !important;
  }

  .industry_child_Section3 {
    left: 2.5rem;
    top: 1rem !important;
  }

  .industry_child_Section4 {
    left: 3.5rem;
    top: 1rem !important;
  }

  .industry_child_Section5 {
    left: 4.5rem;
    top: 1rem !important;
  }

  .industry_child_Section6 {
    left: 5.5rem;
    top: 1rem !important;
  }


  .home_spacing {
    margin-top: 2rem;
    /*  margin-bottom: 2rem; */
    padding: 1rem;
  }

  .main-box {
    top: 7rem !important;
  }

  .home-image {
    top: -11rem;
  }

  /*   .self-image {
       top: -4rem;
     } */

  .section_spacing1 {
    margin-top: 20rem !important;
  }



}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .big-screen {
    display: none;
  }




  /* Help center btn */
  .help_center {
    margin-top: 2rem;
  }

  .top_Space {
    margin-top: 8rem;
  }

  /* 
  .bottom_space {
    border: 2px solid #E4F2FF;
  } */

  .arrow_img {
    display: none;
  }

  .side_space {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .industry_heading {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 44px;
  }

  .sector_head {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .about-text-para {
    text-align: center;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* On large screens */
  }

  .Banking_heading {
    text-align: center;
    /* On large screens */
  }

  .footer_margin_768 {
    margin-top: 3rem !important;
  }

  .footer_margin_bottom {
    margin-bottom: 40rem;
  }

  .font_text {
    font-size: smaller !important;
  }

  .faq_head {
    font-size: small !important;
  }

  .Banking_heading {
    font-family: Poppins;
    font-size: 32px !important;
    font-weight: 600;
    line-height: 46px;
    color: #272626;
    text-align: center;
    text-transform: capitalize;
  }

  .IT_Heading {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
  }

  .header-container {
    height: 400px !important;
  }

  .component_spacing {
    margin-top: 5rem;
  }

  /* Why Hirestar spacing in small screen */

  .top_space {
    margin-top: 3rem;
  }

  .iconn_height {
    height: 53px;
  }


  .About-Heading {
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
    /*     text-align: left;
   */
    /* Reduce the gap between lines */
  }

  .section_headings {
    font-size: 44px;
  }

  /* button width */
  .view_all {
    width: 45%;
  }

  .bg_curve_img {
    display: none;
  }

  .background_color {
    background: #E4F2FF;
  }


  /*   .home_spacing {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }
 */

  .home_spacing {
    margin-top: 2rem;
    /*  margin-bottom: 2rem; */
    padding: 1rem;
  }

  .top_image_gap1 {
    position: relative;
    top: 2rem !important;
    margin-bottom: 5rem;
  }



  .big_size {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .small-screen {
    display: none;
  }

  .Banking_heading {
    font-family: Poppins;
    font-size: 42px !important;
    font-weight: 600;
    line-height: 56px;
    color: #272626;
    text-align: center;
    text-transform: capitalize;
  }

  .top_spacing {
    margin-top: 6rem;
  }

  .about-text-para {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 100% !important;
  }



  .big_size {
    display: none;
  }
}

@media (max-width: 359px) {




  .big_size {
    display: none;
  }

  .big-screen {
    display: none;
  }

  .bottom_space {
    position: relative;
    top: 2rem;
    border: 1px solid #E4F2FF;
  }

  .footer_margin_bottom {
    margin-bottom: 50rem;
  }

  .section_headings {
    font-size: 36px;
  }

  /* button width */
  .view_all {
    width: 45%;
  }

  .background_color {
    background: #E4F2FF;
  }

  .top_image_gap1 {
    position: relative;
    top: 2rem !important;
    margin-bottom: 5rem;
  }
}

/* Space between section */
.section_spacing {
  margin-bottom: 5rem !important;
}



.blog_bg_img {
  background: url("https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/Portal_Image/Portal_bg.png");
  background-size: cover;
  /* Ensures the image covers the div */
  background-position: center;
  /* Keeps the image centered */
  background-repeat: no-repeat;
  width: 100%;

}




.bold-text {
  font-weight: 700 !important;
}

.blog_para {
  font-size: 14px !important;
}

@media (max-width:1023px) {
  .hidden_display {
    display: none !important;
  }
}

@media (min-width:1023px) {
  .show_in_small {
    display: none !important;
  }
}


.border1s {
  border: 7px solid black;
}