.contact-container {
    background-image: url('https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/ContactUsPageAssest/hirestar-location.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    border-radius: 20px;
    position: relative;
    
}

/* mb-[44rem]  md:mb-[24rem] lg:mb-[24rem] */
/* @media () */


@media (max-width:320px) {
    .form-wrapper {
        margin-bottom: 54rem;
    }
}


@media (min-width:320px) and (max-width:425px) {
    .form-wrapper {
        margin-bottom: 44rem;
    }
}


@media (min-width:426px) and (max-width:768px) {
    .form-wrapper {
        margin-bottom: 24rem;
    }
}


/* @media (min-width:1440px) {
    .form-wrapper {
        margin-bottom: 24rem;
    }
} */