/* --------------------------------------------- */

/* media queries for hiding img */

/* for mobile display */

.box-container {
    background-image: url('https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/ContactHirestar/bgblue.svg');
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;
}

/* media queries to hide background image for small device */
@media (max-width: 640px) {
    .box-container {
        background-image: none;
        /* Hide background on small devices */
    }


    .box-top-line {
        display: none;
    }

    .second-row-img {
        display: none;
    }
}


/* media queries to hide background image for medium device */
@media (min-width: 640px) and (max-width: 768px) {
    .box-container {
        background-image: none;
        /* Hide background on medium devices */

    }

    .box-top-line {
        display: none;
    }

    .box-wrapper {
        margin: 0 auto;
        /* border: 1px solid red; */
    }

    .second-row-img {
        display: none;
    }

}

/* center image display more than 768px and less than 1024px */

@media (min-width: 768px) and (max-width: 1024px) {
    .box-container {
        /* background-position: right top  ; */
        /* Example: center the image */
        background-repeat: no-repeat;
        background-size: 95%;
        /* Example: don't repeat the image */

    }
}