/* 640px to 765px */

@media (min-width: 765px) and (max-width: 1023px) {
    .past_emp {
        position: relative;
        top: 5rem;
    }

    .financial_check {
        position: relative;
        top: 5rem;
    }
}