.not-found-title {
    color: #373737;
    font-weight: 700;
    font-size: 57px;
    line-height: 35px;
    /* margin-top: 30px; */
}

.not-found-heading {
    color: #373737;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    margin-top: 13px;
}

.not-found-text-wrapper {
    position: relative;
    z-index: 1;
    bottom: 4rem;
}


.home-btn {
    width: 343px;
    /* margin: 30px auto; */
}

.not-found-div>img {
    width: 75%;
    height: auto;
}

.gearIcon {
    width: 100px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
}


/* Ensure the parent container has a relative position */
.not_found_container {
    position: relative;
    overflow: hidden;
}

/* Shape Top */
.shape-top {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    max-width: 500px;
    height: auto;
}

.shape-top img {
    width: 100%;
    height: auto;
}

/* Shape Bottom */
.shape-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    max-width: 500px;
    height: auto;
}

.shape-bottom img {
    width: 100%;
    height: auto;
}

/* Responsive Adjustments */
@media (max-width: 768px) {

    .not-found-text-wrapper {
        position: relative;
        z-index: 1;
        bottom: 3rem;
    }

    .not-found-title {
        font-size: 48px;
        line-height: 40px;
    }

    .not-found-heading {
        font-size: 20px;
        line-height: 35px;
    }

    


    .gearIcon {
        width: 80px;
    }

    .home-btn {
        width: 300px;
    }

    .btn {
        padding: 8px 18px;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .not-found-text-wrapper {
        position: relative;
        z-index: 1;
        bottom: 2rem;
    }

    .shape-top,
    .shape-bottom {
        width: 70%;
    }

    .not-found-title {
        font-size: 40px;
        line-height: 35px;
    }

    .not-found-heading {
        font-size: 18px;
        line-height: 30px;
    }

    .not-found-div img {
        width: 90%;
    }

    .gearIcon {
        width: 70px;
    }

    .home-btn {
        width: 260px;
    }

    .btn {
        padding: 7px 16px;
        font-size: 13px;
    }
}

/* For mobile devices (max-width: 375px) */
@media (max-width: 375px) {
    .not-found-title {
        font-size: 32px;
        line-height: 28px;
    }

    .not-found-heading {
        font-size: 16px;
        line-height: 25px;
    }

    .not-found-div img {
        width: 100%;
    }

    .gearIcon {
        width: 60px;
    }
    .btn {
        padding: 6px 14px;
        font-size: 12px;
    }
}